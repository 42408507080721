<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveUser)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.variations') }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<InputWithValidation class="mb-3" rules="required|min:2" :label="$tc('fields.name')" v-model="form.name" />

                <div class="columns is-multiline is-mobile mb-3">
					<div v-if="types.length >= 1" class="column is-12-mobile is-6-tablet">
						<SelectMultiple :input_label="$tc('menu.types', 2)" v-model="form.types" :options="types" track-by="name" label="name" />
					</div>

					<div class="column is-12-mobile">
						<SelectWithValidation :label="$tc('fields.format', 1)" v-model="form.type">
							<option v-for="f in formats" :value="f.format" :key="f.format">{{ $t(f.label) }}</option>
						</SelectWithValidation>
					</div>
				</div>

				<span v-if="form.type && form.type == 'select'">
					<h3 class="is-size-6 is-semibold has-text-primary">{{ $tc('fields.variation', variations.length) }}</h3>
					<hr class="mt-2 mb-4" />
					<VariationIncrement :variations="variations" @add-variation="addVariation" @del-variation="delVariation" />
				</span>

				<small class="modal-updated" v-if="form.updated_at">{{ $t('last_change') }} {{ format(form.updated_at) }}</small>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('buttons.save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import SelectMultiple from '@/components/inputs/SelectMultiple'
import VariationIncrement from '@/components/inputs/VariationIncrement'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		InputWithValidation,
		SelectMultiple,
		SelectWithValidation,
		VariationIncrement,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			visible: false,
			form: {
				name: '',
				description: '',
				type: 'select',
				types: []
			},
			variations: [
				{
					name: '',
					image: null, 
					preview: null
				}
			],
			imageVariations: [], 
			formats: [
				{ 
					id: 1, 
					format: 'select', 
					label: 'labels.selection'
				},
				{ 
					id: 2, 
					format: 'text', 
					label: 'labels.text'
				}
			],
			types: [],
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		}
	},
	methods: {
		async findAllTypes() {
            try {
                const response = await Api.get('types/findAll')
                if (response.status === 200) {
                    this.types = response.data
                }
            } catch (e) {
                console.log(e)
            }
        },
		addVariation() {
			this.variations.push({
				id: null,
				name: '',
				image: null
			})
		},
		delVariation(index) {
			let variation = this.variations[index]

			if (variation.id) {
				this.$buefy.dialog.alert({
					size: 'is-delete',
					type: 'is-outlined is-primary',
					title: this.$t('labels.warning') + '!',
					message: '<span>' + this.$t('alerts.delete.confirmation') + '<span>',
					canCancel: true,
					focusOn: 'cancel',
					cancelText: this.$t('buttons.no'),
					confirmText: this.$t('buttons.yes'),
					onConfirm: async () => {
						try {
							const response = await Api.delete(`variations/destroyVar/${variation.id}`)
							if (response.status === 200) {
								this.variations.splice(index, 1)
								successToast(this.$t('alerts.delete.success_f', [this.$tc('fields.variation')]))
							}
						} catch (e) {
							console.log(e)
							errorToast(this.$t('alerts.delete.error_f', [this.$tc('fields.variation')]))
						}
					}
				})
			} else {
				this.variations.splice(index, 1)
			}
		},
		async update() {
			try {
				this.loading = true

				let formData = new FormData()

				for (var i = 0; i < this.form.types.length; i++) {
					formData.append('types[]', this.form.types[i].id)
				}

				this.variations.forEach((v, i) => {
					formData.append('variations[' + i + '][id]', v.id || '')
					formData.append('variations[' + i + '][name]', v.name)
					formData.append('variations[' + i + '][image]', v.image ? v.image : '')
				})

				formData.append('_method', 'put')
				formData.append('name', this.form.name)
				formData.append('description', this.form.description || '')
				formData.append('type', this.form.type)
				formData.append('type_id', this.form.type_id || '')

				const response = await Api.post(`variations/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/variations')
					successToast(this.$t('alerts.update.success_f', [this.$tc('fields.variation')]))
					eventHub.$emit('reload-variations')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('alerts.update.error_f', [this.$tc('fields.variation')]))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				this.variations = []
				this.imageVariations = []
				try {
					const response = await Api.get(`variations/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form.name = data.name
						this.form.description = data.description
						this.form.type = data.type
						this.form.types = data.types

						if (data.type == 'select') { 
							data.variations.forEach((v) => {
								this.variations.push({
									id: v.id,
									name: v.name,
									preview: v.image,
									image: null
								})

								this.imageVariations.push(v.image)
							})
						} else {
							this.addVariation()
						}

						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()

				for (var i = 0; i < this.form.types.length; i++) {
					formData.append('types[]', this.form.types[i].id)
				}

				this.variations.forEach((v, i) => {
					formData.append('variations[' + i + '][name]', v.name)
					formData.append('variations[' + i + '][image]', v.image ? v.image : '')
				})

				formData.append('name', this.form.name)
				formData.append('description', this.form.description || '')
				formData.append('type', this.form.type)
				formData.append('type_id', this.form.type_id || '')

				const response = await Api.post('variations/store', formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/variations')
					successToast(this.$t('alerts.create.success_f', [this.$tc('fields.variation')]))
					eventHub.$emit('reload-variations')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('alerts.create.error_f', [this.$tc('fields.variation')]) + '<small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveUser() {
			this.name === 'New' ? await this.store() : await this.update()
		}
	},
	mounted() {
		this.findAllTypes()
		this.findById()
	}
}
</script>
