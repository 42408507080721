<template>
    <div>
        <div v-for="(v, i) in variations" :key="i" class="columns is-multiline is-mobile mb-0">
            <div class="column is-8-mobile is-9-tablet">
                <InputWithValidation :name="`variation-${i + 1}`" rules="required|min:1" v-model="v.name" />
            </div>
            <div class="column is-2-mobile is-2-tablet">
                <div class="field preview">
                    <div class="icon">
                        <img v-if="v.preview" :src="v.preview" />
                        <div v-else class="vue-swatches__diagonal"></div>
                        <input class="input" type="file" accept="image/*" @change="updateImage($event, v)" /> 
                    </div>
                </div>
            </div>
            <div class="column is-2-mobile is-1-tablet">
                <span v-if="i > 0" class="field remove" @click="remove(i)">
                    <svg-icon icon="minus" :title="$t('labels.remove')"></svg-icon>
                </span>
                <span v-if="i == 0 && variations.length <= 1" class="field more" @click="add">
                    <svg-icon icon="more"></svg-icon>
                </span>
			</div>
        </div>
        <span v-if="variations.length > 1" class="field more add" @click="add">
            <svg-icon icon="more"></svg-icon>
        </span>
    </div>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import Icon from '@/components/Icon'

export default {
    name: 'AttributeVariation',
    components: {
		InputWithValidation,
        'svg-icon': Icon,
	},
    props: {
        variations: {
            type: Array
        }
    },
    methods: {
        add () {
            this.$emit('add-variation')
        },
        remove (index) {
            this.$emit('del-variation', index)
        },
		async updateImage (e, v) { 
			const file = e.target.files[0] 
			v.preview = await this.readFile(file)
			v.image = file
            this.add()
		}
    }
}
</script>
